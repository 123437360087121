import { useContext, useEffect, useState } from "react";
import {
  Button, Container, Grid, Typography, useTheme, Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Select,
  TextField,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
// components
import Label from "../../components/label";
import Scrollbar from "../../components/scrollbar";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import { getCashier, getShop, getShopOwners, searchGame } from "../../data/fetchShopOwner";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
import '../../styles/filter.scss'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: "gameType", label: "Game Type", alignRight: false },
  { id: "gameNumber", label: "Game number", alignRight: false },
  { id: "selection", label: "Winning number", alignRight: false },
  { id: "winner", label: "Winner", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

export default function GameResult(params) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { data, dispatch } = useContext(CampaignContext);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("gameNumber");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [owner, setOwner] = useState([]);

  const today = new Date().toISOString().substring(0, 10);
  const [date, setDate] = useState(today);
  const [number, setNumber] = useState(null);
  const [gameType, setGameType] = useState('keno');


  const handleFetchData = () => {
    searchGame(owner, setOwner, dispatch, setLoader, number, date, gameType);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = owner.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - owner.length) : 0;

  const filteredUsers = applySortFilter(
    owner,
    getComparator(order, orderBy),
    filterName
  );
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>
      <Container maxWidth="xl">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Game Result
          </Typography>
        </Stack> */}

        <Card sx={{ margin: '0rem 0', width: '100%' }} fullWidth>
          <Grid container spacing={3} fullWidth className="fillterGrid">
            <Grid item xs={12} sm={6} xl={3}>
              <Label>Game Type</Label>
              <Select fullWidth value={gameType} onChange={(e) => setGameType(e.target.value)} defaultValue={"keno"}>
                <MenuItem value={"keno"}>Keno</MenuItem>
                <MenuItem value={"spin"}>Spin</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Label>Date</Label>
              <div style={{ width: '100%', display: 'block' }}>
                <input style={{ width: '100%', height: '100%', padding: '10px' }} value={date} onChange={(e) => setDate(e.target.value)} type="date" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Label>Event Id</Label>
              <TextField type="number" value={number} onChange={(e) => setNumber(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} xl={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {
                loader ? (
                  <Button variant="contained" fullWidth style={{ height: '48px', padding: '10px', background: '#7FD858' }}><CircularProgress sx={{ color: 'white' }} /></Button>
                ) : (
                  <Button variant="contained" onClick={handleFetchData} fullWidth style={{ height: '48px', background: '#7FD858' }}>Search Game Result</Button>
                )
              }
            </Grid>
            {/* <Grid item></Grid> */}
          </Grid>
        </Card>

        <Card sx={{ margin: '1rem 0' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={owner.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {
                    // loader ? (<div style={{ height: '50px' }}><CircularProgress /></div>) :
                    filteredUsers && filteredUsers
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const {
                          id,
                          gameNumber,
                          gameType,
                          pickedNumbers,
                          winner,
                          time,
                          status
                        } = row;

                        const selection = JSON.parse(pickedNumbers)?.selection

                        return (
                          <TableRow
                            hover
                            key={id}
                          // tabIndex={-1}
                          // role="checkbox"
                          // selected={selectedUser}
                          >
                            <TableCell>
                              {(index + 1)}
                            </TableCell>

                            <TableCell align="left">{gameType}</TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {gameNumber}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{selection && selection?.length > 1 ? selection.sort((a, b) => {
                              return a - b;
                            }).join(' ,') : selection}</TableCell>
                            <TableCell align="left">{winner}</TableCell>
                            <TableCell align="left">{time && time.substring(0, 10)}</TableCell>
                            <TableCell align="left">{status}</TableCell>
                          </TableRow>
                        );
                      })
                  }
                  {(filteredUsers.length <= 0 && !loader) && (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Game found
                          </Typography>

                          <Typography variant="body2">
                            No results found &nbsp;
                            <strong>&quot;{'Game'}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </TableContainer>
          </Scrollbar>

        </Card>
      </Container>
    </>
  );
};
